import { useRecoilValue } from 'recoil';
import { SuspectParams } from '../domain/SuspectParams';
import { ageState, chargeState, jobState, nameState } from '../states';

export const useSuspectParams = (): SuspectParams => {
    const name = useRecoilValue(nameState);
    const job = useRecoilValue(jobState);
    const age = useRecoilValue(ageState);
    const charges = useRecoilValue(chargeState);

    return { name, job, age, charges };
}

