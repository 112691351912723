import firebase from "firebase/app";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Switch } from '@material-ui/core';
import React from 'react';
import ReactCrop, { Crop } from 'react-image-crop';
import { useRecoilState, useRecoilValue } from 'recoil';
import { getCroppedImg } from '../domain/getCroppedImage';
import { getImageSize } from '../domain/ImageSize';
import { croppedImageSourceState, imageTypeState, originalImageSourceState } from '../states';

export const CropDialog = () => {
    const [originalImageSource, setOriginalImageSource] = useRecoilState(originalImageSourceState);
    const [, setCroppedImageSource] = useRecoilState(croppedImageSourceState);
    const imageType = useRecoilValue(imageTypeState);
    const [fixedAspect, setFixedAspect] = React.useState(true);

    const { width, height } = getImageSize(imageType);

    const aspect = width / height;

    const [crop, setCrop] = React.useState<Crop>(fixedAspect ? {
        aspect,
        unit: '%',
        width: 100,
    } : {
            // unit: '%',
            // width: 50,
            // height: 50,
        });

    // 画像が変わったら初期化する
    React.useEffect(() => {
        setCrop(fixedAspect ? {
            aspect,
            unit: '%',
            width: 100,
        } : {
                // unit: '%',
                // width: 50,
                // height: 50,
            });
    }, [originalImageSource, fixedAspect, aspect, setCrop]);

    const open = !!originalImageSource;
    const disabledSubmit = !crop.width;

    const onCancel = React.useCallback(() => {
        setOriginalImageSource('');
    }, [setOriginalImageSource]);

    const onSubmit = React.useCallback(() => {
        const img = new Image();
        img.crossOrigin = "Anonymous";
        img.src = originalImageSource;
        img.onload = () => {
            setCroppedImageSource(getCroppedImg(img, document.querySelector('img[class="ReactCrop__image"]') as HTMLImageElement, crop, 400));
            setOriginalImageSource('');

            window.location.hash = "#canvas";

            firebase.analytics().logEvent('cropped_image');
        }
    }, [originalImageSource, crop, setCroppedImageSource, setOriginalImageSource]);

    return (
        <Dialog onClose={onCancel} open={open}>
            <DialogTitle>範囲選択</DialogTitle>
            <DialogContent>
                <Box >
                    <FormControl fullWidth={true}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={fixedAspect}
                                    onChange={(event) => setFixedAspect(Boolean(event.target.checked))}
                                    name="fixed-aspect"
                                    color="primary"
                                />
                            }
                            label="アスペクト比の固定"
                        />
                    </FormControl>
                </Box>

                <Box display={"flex"} justifyContent={"center"} alignItems={"center"} mt={2}>
                    <ReactCrop src={originalImageSource} crop={crop} onChange={newCrop => setCrop(newCrop)} />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={onCancel} variant={"outlined"}>
                    キャンセル
				</Button>
                <Button color="primary" disabled={disabledSubmit} variant={"contained"} onClick={onSubmit}>
                    決定
				</Button>
            </DialogActions>
        </Dialog>
    );
};
