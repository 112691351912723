import TwitterMaterialIcon from '@material-ui/icons/Twitter';
import { Box, Container, FormHelperText, Grid, Paper, Typography, TypographyProps } from '@material-ui/core';
import React from 'react';
import {
    FacebookIcon, FacebookShareButton,
    TwitterIcon, TwitterShareButton,
    LineShareButton, LineIcon
} from 'react-share';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { isSmartphone } from '../domain/isSmartPhone';
import { useDownloadCanvasImage } from '../hooks/useDownloadCanvasImage';
import { useOpenCanvasImage } from '../hooks/useOpenCanvasImage';
import { useTermsClick } from '../hooks/useTermsClick';
import { useTweet } from '../hooks/useTweet';
import { croppedImageSourceState } from '../states';
import { AdSenseBanner } from './AdSenseBanner';
import { AspectView } from './AspectView';
import { Button } from './Button';
import { Canvas } from './Canvas';
import { CropDialog } from './CropDialog';
import { AgeField } from './fields/AgeField';
import { ChargeTextField } from './fields/ChargeTextField';
import { JobTextField } from './fields/JobTextField';
import { NameTextField } from './fields/NameTextField';
import { TemplateSelectField } from './fields/TemplateSelectField';
import { TweetTextField } from './fields/TweetTextField';
import { FileDropZone } from './FileDropZone';
import { Footer } from './Footer';
import { NavigationBar } from './NavigationBar';
import { theme } from './NormalizeStyle';
import { TermsSection } from './TermsSection';

const View = styled.div`
background: ${theme.palette.background.default};
`;

const Title = styled((props: TypographyProps) => <Typography {...props} gutterBottom={true} variant={'h3'} />)`
    font-size: 24px;
`;

// TODO: 背景色変えたいかも
// TODO: メモリリーク調査
// TODO: Twitter投稿（https://qiita.com/miura/items/036ef6da8f93bb65caac
// TODO: 罪状は選択あるとらくかも
export const MakePage = () => {
    const croppedImageSource = useRecoilValue(croppedImageSourceState);
    const disabledSubmit = !croppedImageSource;

    const onDownload = useDownloadCanvasImage();
    const onOpenWindow = useOpenCanvasImage();
    const onClickTerms = useTermsClick();
    const onTweet = useTweet();

    return (
        <View>
            <NavigationBar />
            <Box py={4}>
                <Container maxWidth="sm" disableGutters={true}>
                    <Paper >
                        <Box pt={2} pb={4}>
                            <Container maxWidth="sm">
                                <Box>
                                    <Title id="select">画像とフレームを選択</Title>

                                    <AspectView width={3} height={1}>
                                        <FileDropZone />
                                    </AspectView>
                                </Box>

                                <Box mt={4}>
                                    <TemplateSelectField />
                                </Box>
                            </Container>
                        </Box>
                    </Paper>
                </Container>

                <Container maxWidth="sm" disableGutters={true}>
                    <Box mt={4} mb={4}>
                        <AdSenseBanner slotId={"3481129568"} />
                    </Box>
                </Container>

                <Container maxWidth="sm" disableGutters={true}>
                    <Paper>
                        <Canvas />

                        <Box pb={4} >
                            <Container maxWidth="sm">
                                <Box mt={2}>
                                    <NameTextField />
                                </Box>

                                <Box mt={4}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                            <JobTextField />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <AgeField />
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Box mt={4}>
                                    <ChargeTextField />
                                </Box>

                                <Box mt={4}>
                                    <FormHelperText><a href="#terms" onClick={onClickTerms}>利用規約</a>に同意の上</FormHelperText>
                                    <Button fullWidth={true} disabled={disabledSubmit} onClick={isSmartphone() ? onOpenWindow : onDownload}>画像をダウンロード</Button>

                                    <Box mt={1}>
                                        <FormHelperText >
                                            ※ 本サービスで使用する画像はサーバーへ送信・保存されません。安心してご利用ください
                                    </FormHelperText>
                                        <FormHelperText >
                                            スマホをご利用の方は画像を長押しして保存してください
                                    </FormHelperText>
                                    </Box>
                                </Box>

                                <Box mt={4}>
                                    <Box >
                                        <TweetTextField />
                                    </Box>

                                    <Box mt={4}>
                                        <Button fullWidth={true} disabled={disabledSubmit} onClick={onTweet} startIcon={<TwitterMaterialIcon />}>Twitter連携して投稿する</Button>
                                    </Box>
                                </Box>

                                <Box mt={6}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={3} />
                                        <Grid item xs={2}>
                                            <FacebookShareButton url={"http://yougisya.cgig.jp/"}>
                                                <FacebookIcon size={"100%"} round />
                                            </FacebookShareButton>
                                        </Grid>

                                        <Grid item xs={2}>
                                            <TwitterShareButton title={"#容疑者画像メーカー\n\n"} url="http://yougisya.cgig.jp/">
                                                <TwitterIcon size={"100%"} round />
                                            </TwitterShareButton>
                                        </Grid>

                                        <Grid item xs={2}>
                                            <LineShareButton url={"http://yougisya.cgig.jp/"}>
                                                <LineIcon size={"100%"} round />
                                            </LineShareButton>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Container>
                        </Box>
                    </Paper>
                </Container>

                <Container maxWidth="sm" disableGutters={true}>
                    <Box mt={4}>
                        <AdSenseBanner slotId={'1574803919'} />
                    </Box>
                </Container>

                <Container maxWidth="sm" disableGutters={true}>
                    <Box mt={4}>
                        <TermsSection />
                    </Box>
                </Container>
            </Box>

            <CropDialog />

            <Footer />
        </View>
    );
};
