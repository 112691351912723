import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme } from '@material-ui/core/styles';
import {
    ThemeProvider as MaterialUIThemeProvider
} from '@material-ui/styles';
import React from 'react';
import 'react-image-crop/dist/ReactCrop.css';

/**
 * https://material.io/resources/color/#!/?view.left=0&view.right=0
 */
export const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#000000',
            light: '#2c2c2c',
            dark: '#000000',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#ffffff',
            light: '#ffffff',
            dark: '#cccccc',
            contrastText: '#000000',
        },
        type: 'dark',
    },
});


type Props = {
    children: React.ReactNode;
};

export const NormalizeStyle = (props: Props) => {
    const { children } = props;

    return (
        <React.Fragment>
            <CssBaseline />
            <MaterialUIThemeProvider theme={theme}>
                {children}
            </MaterialUIThemeProvider>
        </React.Fragment>
    );
}