import { AppBar, Container, Toolbar, Typography, TypographyProps } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

const Title = styled((props: TypographyProps) => <Typography {...props} variant={'h1'} />)`
    font-size: 24px;
`;

export const NavigationBar = () => {
    return (
        <AppBar position="static">
            <Toolbar>
                <Container maxWidth="sm" disableGutters={true}>
                    <Title>容疑者画像メーカー</Title>
                </Container>
            </Toolbar>
        </AppBar>
    );
}