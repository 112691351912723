import { FormControl, TextField } from '@material-ui/core';
import React from 'react';
import { useRecoilState } from 'recoil';
import { nameState } from '../../states';

export const NameTextField = () => {
    const [name, setName] = useRecoilState(nameState);

    const onChange = React.useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setName(event.target.value);
    }, [setName]);

    return (
        <FormControl fullWidth={true}>
            <TextField
                required={true}
                label={"名前"}
                value={name}
                onChange={onChange}
            />
        </FormControl>
    );
};
