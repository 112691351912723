import FolderIcon from '@material-ui/icons/Folder';
import React from 'react';
import styled from 'styled-components';
import { isSmartphone } from '../domain/isSmartPhone';
import { theme } from './NormalizeStyle';

const View = styled.div`
    cursor: pointer;
    width: 100%;
	height: 100%;
`;

const Inner = styled.div<{ active: boolean }>`
position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
    width: 100%;
	height: 100%;
    background-color: #fff;
    ${(p) => p.active && 'background: #bce2e8'}
`;

const Icon = styled(FolderIcon)`
position: absolute;
width: 120px;
height: 120px;
color: #eee;
`;

// const UploadIcon = styled(({ active, ...rest }: { active: boolean } & SvgIconProps) => (
//     <AttachFileIcon  {...rest} />
// ))`
//     font-size: 90px;
// 	color: #ccc;
// 	transition: color 0.2s;

// 	${(p) => p.active && 'color: #aaa'}
//   `;

const Message = styled.p`
position: relative;
text-align:center;
color: ${theme.palette.background.default};
`;

export type FileDropAreaProps = {
    isDragActive: boolean;
};

const DraggingView = () => {
    return (
        <Inner active={true}>
            <Icon />
            <Message>ここにファイルをドロップ</Message>
        </Inner>
    );
};

const IdleView = () => {
    return (
        <Inner active={false}>
            <Icon />
            <Message>{isSmartphone() ? <>クリックしてファイルを選択</> : <>クリックしてファイルを選択<br />or<br />ここにファイルをドロップ</>}</Message>
        </Inner>
    );
};

export const FileDropArea = (props: FileDropAreaProps): JSX.Element => {
    const { isDragActive } = props;

    return <View>{isDragActive ? <DraggingView /> : <IdleView />}</View>;
};
