import React from 'react';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { useCanvasDrawer } from '../hooks/useCanvasDrawer';
import { AspectView } from './AspectView';
import { getImageSize } from '../domain/ImageSize';
import { imageDataState, imageTypeState } from '../states';

const StyledCanvas = styled.canvas`
    width:100%;
    height:100%;
`;

const FillImage = styled.img`
position: absolute;
width: 100%;
height: 100%;
top:0;
bottom:0;
right:0;
left:0;
`;

export const Canvas = () => {
    const imageType = useRecoilValue(imageTypeState);
    const imageData = useRecoilValue(imageDataState);

    const { width, height } = getImageSize(imageType);

    useCanvasDrawer();

    return (
        <AspectView width={width} height={height}>
            <StyledCanvas id="canvas" width={width} height={height} />
            <FillImage src={imageData} alt={"完成画像"} />
        </AspectView>
    );
};
