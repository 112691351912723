import { Box, Container } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { useTermsClick } from '../hooks/useTermsClick';
import { theme } from './NormalizeStyle';

const View = styled.footer`
	width: 100%;
	color: #fff;
    background: #222;
`;

const MenuList = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	& > :nth-child(n + 2) {
		margin-left: 20px;
	}

	${theme.breakpoints.down('sm')} {
		flex-direction: column;

		& > :nth-child(n + 2) {
			margin-left: 0;
			margin-top: 20px;
		}
	}
`;

const MenuItem = styled.a`
color: #fff;
	&:hover {
		opacity: 0.6;
	}
`;

const Copyright = styled.div`
	padding: 10px 0;
	text-align: center;
	color: #fff;
	background: #000;
`;

export const Footer = () => {
    const onClickTerms = useTermsClick();

    return (
        <View>
            <Container maxWidth={'sm'}>
                <Box py={4}>
                    <MenuList>
                        <MenuItem target={"_blank"} href="https://cgig.jp/">
                            開発チーム
					</MenuItem>
                        <MenuItem target={"_blank"} href="https://cgig.jp/contact">
                            お問い合わせ
					</MenuItem>
                        <MenuItem href="#terms" onClick={onClickTerms}>利用規約</MenuItem>
                        {/* <MenuItem href="/privacy-policy">プライバシポリシー</MenuItem> */}
                    </MenuList>
                </Box>
            </Container>
            <Copyright>Copyright(C) CGIG. All Rights Researved.</Copyright>
        </View>
    );
};
