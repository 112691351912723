import { Crop } from 'react-image-crop';

/**
 * 
 * https://github.com/DominicTobias/react-image-crop/issues/402
 * https://github.com/DominicTobias/react-image-crop/issues/380
 */
export const getCroppedImg = (image: HTMLImageElement, wrap: HTMLImageElement, crop: Crop, maxHeight: number) => {
    const { width = 0, height = 0, x = 0, y = 0 } = crop;

    const canvas = document.createElement('canvas');

    const scaleX = image.naturalWidth / wrap.width;
    const scaleY = image.naturalHeight / wrap.height;

    const aspectRatio = width / height;

    let canvasHeight = height * scaleY;
    let canvasWidth = width * scaleX;

    if (maxHeight && (canvasHeight > maxHeight)) {
        canvasHeight = maxHeight;
        canvasWidth = Math.round(canvasHeight * aspectRatio);
    }

    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    const context = canvas.getContext('2d');
    if (!context) {
        throw new Error(`Context does not exists.`);
    }

    context.drawImage(
        image,
        x * scaleX,
        y * scaleY,
        width * scaleX,
        height * scaleY,
        0,
        0,
        canvasWidth,
        canvasHeight,
    );

    return canvas.toDataURL('image/jpg', 1);
}
