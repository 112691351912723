import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { croppedImageSourceState, imageDataState, templateTypeState, webfontLoadCountState } from '../../states';
import { useSuspectParams } from '../useSuspectParams';
import { drawCanvas } from './drawCanvas';

export const useCanvasDrawer = () => {
    const suspectParams = useSuspectParams();
    const imageSource = useRecoilValue(croppedImageSourceState);
    const templateType = useRecoilValue(templateTypeState);
    const setImageDataState = useSetRecoilState(imageDataState);
    const webfontLoadCount = useRecoilValue(webfontLoadCountState);

    React.useEffect(() => {
        const render = () => {
            drawCanvas(imageSource, templateType, suspectParams).then((data) => {
                setImageDataState(data);
            });
        }

        render();

        // Webfontのために再描画する
        const timeoutId = setTimeout(render, 3000);

        return () => clearTimeout(timeoutId);
    }, [imageSource, templateType, suspectParams, webfontLoadCount, setImageDataState]);
}

