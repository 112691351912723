import { FormControl, TextField } from '@material-ui/core';
import React from 'react';
import { useRecoilState } from 'recoil';
import { ageState } from '../../states';

export const AgeField = () => {
    const [age, setAge] = useRecoilState(ageState);

    const onChange = React.useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setAge(event.target.value);
    }, [setAge]);

    return (
        <FormControl fullWidth={true}>
            <TextField
                label={"年齢"}
                value={age}
                onChange={onChange}
            />
        </FormControl>
    );
};
