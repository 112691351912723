import { SuspectParams } from "../../domain/SuspectParams";
import { TemplateType } from "../../domain/TemplateType";
import { drawWatermark } from "./drawWatermark";
import { drawtemplate1 } from "./template1";
import { drawtemplate2 } from "./template2";
// import watermarkSource from './watermark.png';

const createImageAsync = (src: string): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = "Anonymous";
        img.src = src;
        img.onload = () => {
            resolve(img);
        }
        img.onerror = (error) => {
            reject(error);
        }
    })
}

export const drawCanvas = async (imageSource: string, templateType: TemplateType, suspectParams: SuspectParams): Promise<string> => {
    const canvas = document.getElementById("canvas") as HTMLCanvasElement;
    const context = canvas.getContext("2d");
    if (!context) {
        throw new Error('Context does not exists.')
    }

    let img: HTMLImageElement | null = null;

    if (imageSource) {
        img = await createImageAsync(imageSource);
    }
    // const watermark = await createImageAsync(watermarkSource);

    context.restore();

    context.fillStyle = "#222";
    context.fillRect(0, 0, canvas.width, canvas.height);

    // 画像を描画
    // 高さ固定
    if (img) {
        const imageAspect = img.width / img.height;

        const width = canvas.height * imageAspect;

        context.drawImage(img, (canvas.width - width) / 2, 0, width, canvas.height);
    }

    // テンプレ描画
    switch (templateType) {
        case 1:
            drawtemplate1(canvas, context, suspectParams);
            break;

        case 2:
            drawtemplate2(canvas, context, suspectParams);
            break;
    }

    // ウォーターマーク描画
    // const aspect = watermark.width / watermark.height;
    // context.drawImage(watermark, 10, 10, 100 * aspect, 100 / aspect);
    drawWatermark(canvas, context);

    return canvas.toDataURL("image/jpg");
}