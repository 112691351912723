import { FormControl, TextField } from '@material-ui/core';
import React from 'react';
import { useRecoilState } from 'recoil';
import { jobState } from '../../states';

export const JobTextField = () => {
    const [job, setJob] = useRecoilState(jobState);

    const onChange = React.useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setJob(event.target.value);
    }, [setJob]);

    return (
        <FormControl fullWidth={true}>
            <TextField
                label={"職業"}
                value={job}
                onChange={onChange}
            />
        </FormControl>
    );
};
