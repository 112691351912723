import {
    atom
} from 'recoil';
import { ImageType } from '../domain/ImageSize';
import { TemplateType } from '../domain/TemplateType';

export const captionState = atom({
    key: 'caption',
    default: 'ここにキャプションが入ります',
});

export const telopState = atom({
    key: 'telop',
    default: 'ここにテロップが入ります',
});

export const jobState = atom({
    key: 'job',
    default: '無職',
});

export const nameState = atom({
    key: 'name',
    default: '容疑 太郎',
});

export const ageState = atom({
    key: 'age',
    default: '30',
});

export const chargeState = atom({
    key: 'charge',
    default: '無銭飲食をした疑い',
});

export const originalImageSourceState = atom({
    key: 'originalImageSource',
    default: '',
});

export const croppedImageSourceState = atom({
    key: 'croppedImageSource',
    default: '',
});

export const imageTypeState = atom<ImageType>({
    key: 'imageType',
    default: '16:9',
});

export const templateTypeState = atom<TemplateType>({
    key: 'template',
    default: 1,
});

export const imageDataState = atom({
    key: 'imageData',
    default: '',
});

export const openTermsState = atom({
    key: 'openTerms',
    default: false,
});

export const webfontLoadCountState = atom({
    key: 'webfontLoadCount',
    default: 0,
});

export const tweetState = atom({
    key: 'tweet',
    default: `#容疑者画像メーカー で作ったよ！

http://yougisya.cgig.jp/`,
});

export const loadingState = atom({
    key: 'loading',
    default: false,
});