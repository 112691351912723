import { SuspectParams } from "../../domain/SuspectParams";
import { Rect } from "./utility";

const MARGIN = 160;

type Text = {
    label: string;
    fontSize: number;
    marginLeft?: number;
}

const drawRadiusButton = (context: CanvasRenderingContext2D, x: number, y: number, w: number, h: number, r: number, color: string) => {
    context.beginPath();
    context.lineWidth = 1;
    context.strokeStyle = color;
    context.fillStyle = color;
    context.moveTo(x, y + r);
    context.arc(x + r, y + h - r, r, Math.PI, Math.PI * 0.5, true);
    context.arc(x + w - r, y + h - r, r, Math.PI * 0.5, 0, true);
    context.arc(x + w - r, y + r, r, 0, Math.PI * 1.5, true);
    context.arc(x + r, y + r, r, Math.PI * 1.5, Math.PI, true);
    context.closePath();
    context.stroke();
    context.fill();
}

const drawArrestMark = (context: CanvasRenderingContext2D): Rect => {
    const chargeX = MARGIN;
    const chargeY = 440;
    const paddingX = 20;
    const paddingY = 2;

    const boxRect: Rect = { x: chargeX, y: chargeY, width: 0, height: 0 };

    // テキストでboxRect計算
    context.font = `28px "Noto Sans JP"`;
    context.fillStyle = "#303030";
    context.textAlign = "left";
    context.textBaseline = "bottom";

    const metrics = context.measureText(`逮 捕`);

    boxRect.width = metrics.width + paddingX * 2;
    boxRect.height = metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent + paddingY * 2;

    // 背景描画
    drawRadiusButton(context, boxRect.x, boxRect.y, boxRect.width, boxRect.height, boxRect.height / 2, '#ffffff');

    context.fillStyle = "#000";
    context.textAlign = "center";
    context.textBaseline = "middle";
    context.fillText(`逮 捕`, boxRect.x + boxRect.width / 2, boxRect.y + boxRect.height / 2);

    return boxRect;
}


const drawCaption = (context: CanvasRenderingContext2D, suspectParams: SuspectParams, arrestMarkRect: Rect) => {
    const { charges } = suspectParams;
    const marginLeft = 20;
    const x = arrestMarkRect.x + arrestMarkRect.width + marginLeft;
    const y = arrestMarkRect.y + arrestMarkRect.height / 2;


    context.font = `32px "Noto Sans JP"`;
    context.fillStyle = "#fff";
    context.textAlign = "left";
    context.textBaseline = "middle";
    context.fillText(charges, x, y);
}


const drawTelop = (canvas: HTMLCanvasElement, context: CanvasRenderingContext2D, captionRect: Rect, suspectParams: SuspectParams) => {
    const { name, job, age } = suspectParams;

    const width = canvas.width - MARGIN * 2;
    const height = 70;
    const marginTop = 5;

    const texts: Text[] = [];

    if (job) {
        texts.push({ label: job, fontSize: 32 });
    }

    texts.push({ label: name, fontSize: 38, marginLeft: job ? 10 : 0 });

    texts.push({ label: '容疑者', fontSize: 32, marginLeft: 10 });

    if (age) {
        texts.push({ label: `(${age})`, fontSize: 32, });
    }

    const rect: Rect = {
        x: MARGIN,
        y: captionRect.y + captionRect.height + marginTop,
        width,
        height,
    }

    // 背景描画
    context.fillStyle = "#000000CC";
    context.fillRect(rect.x, rect.y, rect.width, rect.height);

    // テキスト描画
    // まず測定
    let totalWidth = 0;
    let minHeight = rect.height;

    let currentX = rect.x + 5;
    for (const text of texts) {
        const { label, fontSize, marginLeft } = text;

        context.font = `${fontSize}px "Noto Sans JP"`;
        context.fillStyle = "#dedede";
        context.textAlign = "left";
        context.textBaseline = "bottom";

        const metrics = context.measureText(label);

        totalWidth += metrics.width + (marginLeft ?? 0);
        minHeight = Math.min(minHeight, metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent)
    }

    currentX = rect.x + 5;
    for (const text of texts) {
        const { label, fontSize, marginLeft } = text;

        currentX += marginLeft ?? 0;

        context.font = `${fontSize}px "Noto Sans JP"`;
        context.fillStyle = "#dedede";
        context.textAlign = "left";
        context.textBaseline = "bottom";

        const metrics = context.measureText(label);

        const x = currentX + (rect.width - totalWidth) / 2;
        const y = rect.y + rect.height / 2 + minHeight / 2;
        context.fillText(label, x, y);

        currentX += metrics.width;
    }
}


export const drawtemplate2 = (canvas: HTMLCanvasElement, context: CanvasRenderingContext2D, suspectParams: SuspectParams) => {
    const arrestMarkRect = drawArrestMark(context);

    drawCaption(context, suspectParams, arrestMarkRect);
    drawTelop(canvas, context, arrestMarkRect, suspectParams);
}

