import { FormControl, Grid, Paper } from '@material-ui/core';
import React from 'react';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';
import { AspectView } from '../AspectView';
import { templateTypeState } from '../../states';

const Image = styled(({ selected, ...rest }: { selected: boolean } & React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>) => (
  <img alt={rest.alt} {...rest} />
))`
width: 100%;
height: 100%;
cursor: pointer;
${p => p.selected && 'border: solid 2px red;'}
`;

/**
 * フレーム選択
 */
export const TemplateSelectField = () => {
  const [templateType, setTemplateType] = useRecoilState(templateTypeState);

  return (
    <FormControl component="fieldset" fullWidth={true}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Paper>
            <AspectView width={16} height={9}>
              <Image selected={templateType === 1} src="/images/sample1.jpg" onClick={() => setTemplateType(1)} alt={"サンプル画像1"} />
            </AspectView>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper>
            <AspectView width={16} height={9}>
              <Image selected={templateType === 2} src="/images/sample2.jpg" onClick={() => setTemplateType(2)} alt={"サンプル画像2"} />
            </AspectView>
          </Paper>
        </Grid>
      </Grid>
    </FormControl>
  );
}