import { FormControl, TextField } from '@material-ui/core';
import React from 'react';
import { useRecoilState } from 'recoil';
import { chargeState } from '../../states';

export const ChargeTextField = () => {
    const [charge, setCharge] = useRecoilState(chargeState);

    const onChange = React.useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setCharge(event.target.value);
    }, [setCharge]);

    return (
        <FormControl fullWidth={true}>
            <TextField
                required={true}
                label={"罪状"}
                multiline
                rowsMax={2}
                value={charge}
                onChange={onChange}
            />
        </FormControl>
    );
};
