import React from 'react';

export type SlotId = '3481129568' | '1574803919';

type Props = {
    slotId: SlotId;
};

export const AdSenseBanner = (props: Props) => {
    const { slotId } = props;

    React.useEffect(() => {
        if (process.env.NODE_ENV !== "development") {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        }
    }, [])

    if (process.env.NODE_ENV === "development") {
        return <div style={{ width: '100%', height: 200, background: '#000' }} />;
    }

    return (
        <ins className="adsbygoogle"
            style={{ "display": "block" }}
            data-ad-client={'ca-pub-5033607041654328'}
            data-ad-slot={slotId}
            data-ad-format="auto"
            data-full-width-responsive="true"></ins>
    )
};