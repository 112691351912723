import { FormControl, TextField } from '@material-ui/core';
import React from 'react';
import { useRecoilState } from 'recoil';
import { tweetState } from '../../states';

export const TweetTextField = () => {
    const [tweet, setTweet] = useRecoilState(tweetState);

    const onChange = React.useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setTweet(event.target.value);
    }, [setTweet]);

    return (
        <FormControl fullWidth={true}>
            <TextField
                required={true}
                label={"Tweet"}
                multiline
                rowsMax={4}
                value={tweet}
                onChange={onChange}
            />
        </FormControl>
    );
};
