import { Accordion, AccordionDetails, AccordionSummary, Button, Typography, TypographyProps } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import { useRecoilState } from "recoil";
import styled from 'styled-components';
import { openTermsState } from "../states";

const Title = styled((props: TypographyProps) => <Typography {...props} gutterBottom={true} variant={'h2'} />)`
    font-size: 18px;
`;

export const TermsSection = () => {
  const [openTerms, setOpenTerms] = useRecoilState(openTermsState);

  return (
    <Accordion expanded={openTerms} onClick={() => setOpenTerms(!openTerms)}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="terms"
        id="terms"
      >
        <Typography>利用規約</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div>
          <p>
            この利用規約（以下、「本規約」といいます。）は、CGIG（以下、「運営者」といいます。）がこのウェブサイト上で提供するサービス（以下、「本サービス」といいます。）の利用条件を定めるものです。登録ユーザーの皆さま（以下、「ユーザー」といいます。）には、本規約に従って、本サービスをご利用いただきます。
				</p>
          <p>
            なお、本規約については、お客様が利用開始した時点で同意されたものとさせていただきますので、ご利用の前に必ずお読みください。
				</p>

          <Title>第1条（適用）</Title>
          <ol>
            <li>
              本規約は、ユーザーと運営者との間の本サービスの利用に関わる一切の関係に適用されるものとします。
					</li>
            <li>
              運営者は本サービスに関し、本規約のほか、ご利用にあたってのルール等、各種の定め（以下、「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず、本規約の一部を構成するものとします。
					</li>
            <li>
              本規約の規定が前条の個別規定の規定と矛盾する場合には、個別規定において特段の定めなき限り、個別規定の規定が優先されるものとします。
					</li>
          </ol>

          <Title>第2条（利用登録）</Title>
          <ol>
            <li>
              本サービスにおいては、登録希望者が本規約に同意の上、運営者の定める方法によって利用登録を申請し、運営者がこれを承認することによって、利用登録が完了するものとします。
					</li>
            <li>
              運営者は、利用登録の申請者に以下の事由があると判断した場合、利用登録の申請を承認しないことがあり、その理由については一切の開示義務を負わないものとします。
						<ol>
                <li>利用登録の申請に際して虚偽の事項を届け出た場合</li>
                <li>本規約に違反したことがある者からの申請である場合</li>
                <li>その他、運営者が利用登録を相当でないと判断した場合</li>
              </ol>
            </li>
          </ol>

          <Title>第3条（アカウント）</Title>
          <ol>
            <li>
              ユーザーは、本サービスの利用に際してユーザーご自身に関する情報を登録する場合、真実、正確かつ完全な情報を提供しなければならず、常に最新の情報を登録するものとします。
					</li>
            <li>
              ユーザーは、本サービスの利用に際してパスワードを登録する場合、これを第三者に不正に利用されないようご自身で厳重に管理しなければなりません。当社は、登録されたID及びパスワードを利用して行われた本サービス上の一切の行為を、ユーザー自身の行為とみなすことができます。
					</li>
            <li>本サービスに登録したユーザーは、いつでもアカウントを削除して退会することができます。</li>
            <li>
              当社は、ユーザーが本規約に違反しまたは違反するおそれがあると認めた場合、あらかじめユーザーに通知することなく、アカウントを停止または削除することができます。
					</li>
            <li>
              当社は、最終のアクセスから１年間以上経過しているアカウントを、あらかじめユーザーに通知することなく削除することができます。
					</li>
            <li>
              ユーザーの本サービスを利用する権利のすべては、理由を問わず、アカウントが削除された時点で消滅します。ユーザーが誤ってアカウントを削除した場合であっても、アカウントの復旧はできません。
					</li>
            <li>
              本サービスのアカウントは、ユーザーに一身専属的に帰属します。ユーザーの本サービスにおける全ての利用権は、第三者に譲渡、貸与または相続させることはできません。
					</li>
          </ol>

          <Title>第4条（禁止事項）</Title>
          <p>ユーザーは、本サービスの利用にあたり、以下の行為をしてはなりません。</p>

          <ol>
            <li>法令または公序良俗に違反する行為</li>
            <li>犯罪行為に関連する行為</li>
            <li>本サービスの内容等、本サービスに含まれる著作権、商標権ほか知的財産権を侵害する行為</li>
            <li>
              運営者、ほかのユーザー、またはその他第三者のサーバーまたはネットワークの機能を破壊したり、妨害したりする行為
					</li>
            <li>本サービスによって得られた情報を商業的に利用する行為</li>
            <li>運営者のサービスの運営を妨害するおそれのある行為</li>
            <li>不正アクセスをし、またはこれを試みる行為</li>
            <li>他のユーザーに関する個人情報等を収集または蓄積する行為</li>
            <li>不正な目的を持って本サービスを利用する行為</li>
            <li>本サービスの他のユーザーまたはその他の第三者に不利益、損害、不快感を与える行為</li>
            <li>他のユーザーに成りすます行為</li>
            <li>運営者が許諾しない本サービス上での宣伝、広告、勧誘、または営業行為</li>
            <li>面識のない異性との出会いを目的とした行為</li>
            <li>運営者のサービスに関連して、反社会的勢力に対して直接または間接に利益を供与する行為</li>
            <li>その他、運営者が不適切と判断する行為</li>
          </ol>

          <Title>第5条（本サービスの提供の停止等）</Title>
          <ol>
            <li>
              運営者は、以下のいずれかの事由があると判断した場合、ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
						<ol>
                <li>本サービスにかかるコンピュータシステムの保守点検または更新を行う場合</li>
                <li>
                  地震、落雷、火災、停電または天災などの不可抗力により、本サービスの提供が困難となった場合
							</li>
                <li>コンピュータまたは通信回線等が事故により停止した場合</li>
                <li>その他、運営者が本サービスの提供が困難と判断した場合</li>
              </ol>
            </li>
            <li>
              運営者は、本サービスの提供の停止または中断により、ユーザーまたは第三者が被ったいかなる不利益または損害についても、一切の責任を負わないものとします。
					</li>
          </ol>

          <Title>第6条（利用制限および登録抹消）</Title>
          <ol>
            <li>
              運営者は、ユーザーが以下のいずれかに該当する場合には、事前の通知なく、ユーザーに対して、本サービスの全部もしくは一部の利用を制限し、またはユーザーとしての登録を抹消することができるものとします。
						<ol>
                <li>本規約のいずれかの条項に違反した場合</li>
                <li>登録事項に虚偽の事実があることが判明した場合</li>
                <li>料金等の支払債務の不履行があった場合</li>
                <li>運営者からの連絡に対し、一定期間返答がない場合</li>
                <li>本サービスについて、最終の利用から一定期間利用がない場合</li>
                <li>その他、運営者が本サービスの利用を適当でないと判断した場合</li>
              </ol>
            </li>
            <li>
              運営者は、本条に基づき運営者が行った行為によりユーザーに生じた損害について、一切の責任を負いません。
					</li>
          </ol>

          <Title>第7条（保証の否認および免責事項）</Title>
          <ol>
            <li>
              運営者は、本サービスに事実上または法律上の瑕疵（安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性、セキュリティなどに関する欠陥、エラーやバグ、権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。
					</li>
            <li>
              運営者は、本サービスに起因してユーザーに生じたあらゆる損害について一切の責任を負いません。ただし、本サービスに関する運営者とユーザーとの間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合、この免責規定は適用されません。
					</li>
            <li>
              前項ただし書に定める場合であっても、運営者は、運営者の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害のうち特別な事情から生じた損害（運営者またはユーザーが損害発生につき予見し、または予見し得た場合を含みます。）について一切の責任を負いません。また、運営者の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害の賠償は、ユーザーから当該損害が発生した月に受領した利用料の額を上限とします。
					</li>
            <li>
              運営者は、本サービスに関して、ユーザーと他のユーザーまたは第三者との間において生じた取引、連絡または紛争等について一切責任を負いません。
					</li>
          </ol>

          <Title>第8条（サービス内容の変更等）</Title>
          <p>
            運営者は、ユーザーに通知することなく、本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし、これによってユーザーに生じた損害について一切の責任を負いません。
				</p>

          <Title>第9条（利用規約の変更）</Title>
          <p>
            運営者は、必要と判断した場合には、ユーザーに通知することなくいつでも本規約を変更することができるものとします。なお、本規約の変更後、本サービスの利用を開始した場合には、当該ユーザーは変更後の規約に同意したものとみなします。
				</p>

          <Title>第10条（個人情報の取扱い）</Title>
          <p>
            運営者は、本サービスの利用によって取得する個人情報については、運営者「プライバシーポリシー」に従い適切に取り扱うものとします。
				</p>

          <Title>第11条（通知または連絡）</Title>
          <p>
            ユーザーと運営者との間の通知または連絡は、運営者の定める方法によって行うものとします。運営者は,ユーザーから,運営者が別途定める方式に従った変更届け出がない限り,現在登録されている連絡先が有効なものとみなして当該連絡先へ通知または連絡を行い,これらは,発信時にユーザーへ到達したものとみなします。
				</p>

          <Title>第12条（権利義務の譲渡の禁止）</Title>
          <p>
            ユーザーは、運営者の書面による事前の承諾なく、利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し、または担保に供することはできません。
				</p>

          <Title>第13条（準拠法・裁判管轄）</Title>
          <ol>
            <li>本規約の解釈にあたっては、日本法を準拠法とします。</li>
            <li>
              本サービスに関して紛争が生じた場合には、運営者の本店所在地を管轄する裁判所を専属的合意管轄とします。
					</li>
          </ol>

          <p>令和3年1月24日施行</p>

          <p style={{ textAlign: 'right' }}>以上</p>

          <Button onClick={() => setOpenTerms(false)} fullWidth={true} color={"primary"} variant={"contained"}>
            閉じる
        </Button>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}