export const imageTypes = ['16:9', '1:1'] as const;

export type ImageType = typeof imageTypes[number];

export type Size = { width: number; height: number; };

export const getImageSize = (type: ImageType): Size => {
    switch (type) {
        case '1:1':
            return { width: 1200, height: 1200 };

        case '16:9':
            return { width: 1014, height: 570 };

        default:
            throw new Error(`Invalid image type ${type}`);
    }
}

export const getImageTypeLabel = (type: ImageType): string => {
    switch (type) {
        case '1:1':
            return '正方形'

        case '16:9':
            return '16:9';

        default:
            throw new Error(`Invalid image type ${type}`);
    }
}