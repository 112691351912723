// @ts-ignore
import { OAuth } from 'oauthio-web';
import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { loadingState, tweetState } from '../states';

const getBase64ImageString = (): string => {
    const canvas = document.getElementById("canvas") as HTMLCanvasElement;
    const context = canvas.getContext("2d");
    if (!context) {
        throw new Error('Context does not exists.')
    }

    return canvas.toDataURL("image/jpg");
}

/**
 * https://console.cloud.google.com/functions/list?hl=ja&project=cgig-tools
 */
const tweetWithAuth = (tweet: string): Promise<void> => {
    return new Promise((resolve, reject) => {
        OAuth.initialize('LuyEizlr6PqHIOvE8Iw4reQK_fI')
        OAuth.popup('twitter').done((result: any) => {
            console.log(result);

            const url = `https://asia-northeast1-cgig-tools.cloudfunctions.net/tweet-with-media`;

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                mode: "no-cors",
                body: JSON.stringify({
                    tweet,
                    media: getBase64ImageString(),
                    access_token_key: result.oauth_token,
                    access_token_secret: result.oauth_token_secret,
                })
            })
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        }).fail((error: Error) => {
            reject(error);
        });
    });
}

/**
 * https://qiita.com/miura/items/036ef6da8f93bb65caac
 */
export const useTweet = () => {
    const tweet = useRecoilValue(tweetState);
    const setLoading = useSetRecoilState(loadingState);

    return React.useCallback(() => {
        setLoading(true);
        tweetWithAuth(tweet).then(() => {
            alert(`Twitterに投稿しました`);
        }).catch((error: Error) => {
            console.error(error);
            alert(`Twitterへの投稿に失敗しました`);
        }).finally(() => {
            setLoading(false);
        });
    }, [tweet, setLoading]);
};