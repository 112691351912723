import "firebase/analytics";
import firebase from "firebase/app";
import React from 'react';

export const FirebaseProvider = React.memo(() => {
    try {
        firebase.initializeApp({
            apiKey: "AIzaSyBnL-TcUMA1oJog-tmGgR0K4q3-xliiQbs",
            authDomain: "cgig-tools.firebaseapp.com",
            projectId: "cgig-tools",
            storageBucket: "cgig-tools.appspot.com",
            messagingSenderId: "255034917378",
            appId: "1:255034917378:web:21ec5344b7bc9beed6dd1b",
            measurementId: "G-11G7MQYMFB"
        });
    } catch (error) { }

    firebase.app().analytics().setCurrentScreen('/');

    return null;
});