import { Rect } from './utility';

/**
 * ロゴ描画
 */
export const drawWatermark = (canvas: HTMLCanvasElement, context: CanvasRenderingContext2D) => {
    const rect: Rect = {
        x: 20,
        y: 50,
        width: 160,
        height: 70,
    };

    // context.fillStyle = "#ffffffaa";
    // context.fillRect(rect.x, rect.y, rect.width, rect.height);

    context.font = `26px "Noto Sans JP"`;
    context.textAlign = "left";
    context.textBaseline = "middle";
    context.lineWidth = 3;
    // context.fillText("#容疑者画像メーカー", rect.x + rect.width / 2, rect.y + rect.height / 2, rect.width);
    context.fillStyle = "#000";
    context.strokeStyle = "#000";
    context.strokeText("#容疑者画像メーカー", rect.x, rect.y);

    context.fillStyle = "#fff";
    context.lineWidth = 0;
    context.fillText("#容疑者画像メーカー", rect.x, rect.y);
}
