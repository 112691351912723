import React from 'react';
import {
  RecoilRoot
} from 'recoil';
import { FirebaseProvider } from './FirebaseProvider';
import { LoadingOverlay } from './LoadingOverlay';
import { MakePage } from './MakePage';
import { NormalizeStyle } from './NormalizeStyle';
import { WebFontProvider } from './WebFontProvider';

export const App = () => {
  return (
    <NormalizeStyle>
      <FirebaseProvider />
      <RecoilRoot>
        <WebFontProvider />
        <MakePage />
        <LoadingOverlay/>
      </RecoilRoot>
    </NormalizeStyle>
  );
};
