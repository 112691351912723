import React from 'react';
import { useRecoilState } from 'recoil';
import { openTermsState } from '../states';

export const useTermsClick = () => {
    const [, setOpenTerms] = useRecoilState(openTermsState);

    return React.useCallback(() => {
        setOpenTerms(true);

        setTimeout(() => {
            const element = document.getElementById('terms');
            const { top = 0 } = element?.getBoundingClientRect() ?? {};
            const elemtop = top + window.pageYOffset;
            document.documentElement.scrollTop = elemtop;
        }, 500);
    }, [setOpenTerms]);
}

