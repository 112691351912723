import React from 'react';
import { useRecoilState } from 'recoil';
import WebFont from 'webfontloader';
import { webfontLoadCountState } from '../states';

export const WebFontProvider = () => {
    const [initialized, setInitialized] = React.useState(false);
    const [webfontLoadCount, setWebfontLoadCount] = useRecoilState(webfontLoadCountState);

    if (!initialized) {
        setInitialized(true);

        WebFont.load({
            google: {
                families: ['Roboto:300,400,500,700', 'Noto+Sans+JP:700'],
                // text: "#容疑者画像メーカー無職太郎無銭飲食をした疑い",
            },
            // loading: () => {
            //     console.log('loading');
            // },
            active: () => {
                sessionStorage.fonts = true;

                console.log('active');

                setWebfontLoadCount(webfontLoadCount + 1);
            },
            // inactive: () => {
            //     console.log('inactive');
            // },
            // fontloading: (familyName: string, fvd: string) => {
            //     console.log('fontloading', {familyName, fvd});
            // },
            // fontactive: (familyName: string, fvd: string) => {
            //     console.log('fontactive', {familyName, fvd});
            // },
            // fontinactive: (familyName: string, fvd: string) => {
            //     console.log('fontinactive', {familyName, fvd});
            // },
        });
    }

    return null;
};

