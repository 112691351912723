import { SuspectParams } from "../../domain/SuspectParams";
import { Rect } from "./utility";

const MARGIN = 80;

type Text = {
    label: string;
    fontSize: number;
    marginLeft?: number;
}

const drawCaption = (context: CanvasRenderingContext2D, suspectParams: SuspectParams): Rect => {
    const { name, job, age } = suspectParams;
    const captionX = MARGIN;
    const captionY = 380;

    const texts: Text[] = [];

    if (job) {
        texts.push({ label: job, fontSize: 24 });
    }

    texts.push({ label: name, fontSize: 28, marginLeft: job ? 10 : 0 });

    texts.push({ label: '容疑者', fontSize: 24, marginLeft: 6 });

    if (age) {
        texts.push({ label: `(${age})`, fontSize: 24, marginLeft: 3 });
    }

    const boxRect: Rect = { x: captionX, y: captionY, width: 0, height: 0 };

    // テキストでboxRect計算
    let currentX = captionX + 5;
    let maxHeight = 0;
    let minHeight = 1000;
    for (const text of texts) {
        const { label, fontSize, marginLeft } = text;

        currentX += marginLeft ?? 0;

        context.font = `${fontSize}px "Noto Sans JP"`;
        context.fillStyle = "#303030";
        context.textAlign = "left";
        context.textBaseline = "bottom";

        const metrics = context.measureText(label);

        currentX += metrics.width;
        maxHeight = Math.max(maxHeight, metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent);
        minHeight = Math.min(minHeight, metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent)
    }

    boxRect.width = currentX - captionX + 5;
    boxRect.height = maxHeight + 5;

    // 背景描画
    context.fillStyle = "#EEE";
    context.fillRect(boxRect.x, boxRect.y, boxRect.width, boxRect.height);

    // テキスト描画
    currentX = captionX + 5;
    for (const text of texts) {
        const { label, fontSize, marginLeft } = text;

        currentX += marginLeft ?? 0;

        context.font = `${fontSize}px "Noto Sans JP"`;
        context.fillStyle = "#303030";
        context.textAlign = "left";
        context.textBaseline = "bottom";
        context.fillText(label, currentX, boxRect.y + boxRect.height / 2 + minHeight / 2);

        const metrics = context.measureText(label);

        currentX += metrics.width;
    }

    return boxRect;
}

const drawTelop = (canvas: HTMLCanvasElement, context: CanvasRenderingContext2D, captionRect: Rect, suspectParams: SuspectParams) => {
    const { charges } = suspectParams;

    const width = canvas.width - MARGIN * 2;
    const height = 120;
    const marginTop = 5;
    const padding = 10;

    const rect: Rect = {
        x: MARGIN,
        y: captionRect.y + captionRect.height + marginTop,
        width,
        height,
    }

    // 背景描画
    context.fillStyle = "#000000CC";
    context.fillRect(rect.x, rect.y, rect.width, rect.height);

    // テキスト描画
    const lines = charges.split('\n');

    context.font = `32px "Noto Sans JP"`;
    context.fillStyle = "#ffffff";
    context.textAlign = "left";
    context.textBaseline = "middle";

    // まず測定する
    let maxWidth = 0;
    let maxHeight = 0;
    for (const line of lines) {
        const metrics = context.measureText(line);
        maxWidth = Math.max(maxWidth, metrics.width);
        maxHeight = Math.max(maxHeight, metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent);
    }

    let currentY = lines.length === 1 ? rect.y + padding + maxHeight / 2 : rect.y + padding;
    for (const line of lines) {
        const metrics = context.measureText(line);
        const x = rect.x + (rect.width - maxWidth) / 2;
        const y = currentY + (metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent) / 2;
        context.fillText(line, x, y);
        currentY += metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent;
    }
}

export const drawtemplate1 = (canvas: HTMLCanvasElement, context: CanvasRenderingContext2D, suspectParams: SuspectParams) => {
    const captionRect = drawCaption(context, suspectParams);
    drawTelop(canvas, context, captionRect, suspectParams);
}

